import JWVideoPlayer from '../../components/JWVideoPlayer';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { PortalWithState } from 'react-portal';
import ReactResizeDetector from 'react-resize-detector';
import ScrollLock from 'react-scrolllock';
import Overlay from '../../shared/Overlay';

import BackgroundImage from '../../components/BackgroundImage';
import FlexibleHeightContainer from '../../components/FlexibleHeightContainer';

import * as styles from './TeaserImage.module.css';

TeaserImage.propTypes = {
  data: PropTypes.shape().isRequired,
};
TeaserImage.defaultProps = {};

function TeaserImage({ data }) {
  const { title, text, target, image } = data;

  if (target === '#video') {
    return (
      <div className={styles.block}>
        <PortalWithState
          closeOnOutsideClick
          closeOnEsc
          node={typeof document !== 'undefined' && document.querySelector('#portal')}
        >
          {({ openPortal, closePortal, portal }) => (
            <React.Fragment>
              <ReactResizeDetector handleWidth>
                {(width) => (
                  <FlexibleHeightContainer aspectRatio={50 / 57} width={width || 400} onClick={openPortal}>
                    <BackgroundImage image={image} />
                    <div className={styles.overlay}>
                      <h4>{title}</h4>
                      <span>{text}</span>
                    </div>
                  </FlexibleHeightContainer>
                )}
              </ReactResizeDetector>
              {portal(
                <React.Fragment>
                  <ScrollLock />
                  <Overlay closePortal={closePortal} theme="video">
                    <JWVideoPlayer />
                  </Overlay>
                </React.Fragment>,
              )}
            </React.Fragment>
          )}
        </PortalWithState>
      </div>
    );
  }

  if (target.indexOf('.pdf') !== -1) {
    return (
      <a className={styles.block} key={text} href={target} target="_blank" rel="noreferrer noopener">
        <ReactResizeDetector handleWidth>
          {(width) => (
            <FlexibleHeightContainer aspectRatio={50 / 57} width={width || 400}>
              <BackgroundImage image={image} />
              <div className={styles.overlay}>
                <h4>{title}</h4>
                <span>{text}</span>
              </div>
            </FlexibleHeightContainer>
          )}
        </ReactResizeDetector>
      </a>
    );
  }

  return (
    <Link className={styles.block} key={text} to={target}>
      <ReactResizeDetector handleWidth>
        {(width) => (
          <FlexibleHeightContainer aspectRatio={50 / 57} width={width || 400}>
            <BackgroundImage image={image} />
            <div className={styles.overlay}>
              <h4>{title}</h4>
              <span>{text}</span>
            </div>
          </FlexibleHeightContainer>
        )}
      </ReactResizeDetector>
    </Link>
  );
}

export default TeaserImage;
