import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MadeInAustriaOverlay from '../../components/MadeInAustriaOverlay';
import Image from '../../shared/Image';
import Button from '../../shared/Button';

import * as styles from './TeaserBlock.module.css';

TeaserBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape(),
    link: PropTypes.string,
    linkLabel: PropTypes.string,
  }),
  showMadeInAustria: PropTypes.bool,
};
TeaserBlock.defaultProps = {
  data: false,
  showMadeInAustria: false,
};

function TeaserBlock({ data, showMadeInAustria }) {
  const { titel: title, text, link, linkLabel, image } = data;

  return (
    <section className={clsx(styles.TeaserBlock, 'defaultWidth')}>
      {title && title && <h2>{title}</h2>}
      {text && text && (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      {link && link && <Button href={link}>{linkLabel}</Button>}
      {image && (
        <div className={styles.image}>
          <Image data={image} />
        </div>
      )}
      {showMadeInAustria && <MadeInAustriaOverlay color="black" />}
    </section>
  );
}

export default TeaserBlock;
