import { graphql } from 'gatsby';
import React from 'react';

import CallToAction from '../blocks/CallToAction';
import Teaser from '../blocks/TeaserBlock';
import TeaserWithBackground from '../blocks/TeaserBlockWithBackground';
import TeaserImages from '../blocks/TeaserImagesBlock';
import ProductTeaser from '../blocks/ProductTeaserBlock';
import FullWidthImage from '../blocks/FullWidthImage';
import { useTranslation } from 'react-i18next';
// import cleanCockpitData from 'Utils/cleanCockpitData';
import Metadata from '../components/Metadata';
import DefaultLayout from '../layout/Default';
import HeaderImage from '../blocks/HeaderImage';
import * as styles from './index.module.css';

const IndexPage = ({ data, location }) => {
  const { pageData, siteData, contactData, backgroundImage, products } = data;
  const { title, canonicalSiteUrl } = siteData.siteMetadata;
  const { t } = useTranslation('products');

  const { header, heroImage, teasers, callToAction, teaserText1, teaserText2, teaserText3, metadata, opengraph } =
    pageData;

  const productsAsArray = products.edges.map(({ node: product }) => product);
  return (
    <DefaultLayout>
      <div className={styles.Homepage}>
        <HeaderImage data={header} showMadeInAustria={false} />
        <Teaser data={teaserText1} showMadeInAustria />
        <TeaserImages data={teasers} backgroundImage={backgroundImage} />
        <Teaser data={teaserText2} />
        <FullWidthImage data={heroImage} showMadeInAustria={false} />
        <TeaserWithBackground data={teaserText3} showMadeInAustria />{' '}
        <CallToAction data={callToAction} contactData={contactData} />
        <ProductTeaser data={productsAsArray} title={t('title')} />
        <Metadata
          data={metadata}
          opengraph={opengraph}
          defaultTitle={title}
          canonicalSiteUrl={canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
};

export default /* cleanCockpitData( */ IndexPage /* ) */;

export const IndexPageQuery = graphql`
  query IndexPageQuery($language: String) {
    backgroundImage: file(publicURL: { regex: "/imageteaser_background1/" }) {
      publicURL
      processed: childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }

    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    products: allCockpitProducts(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          label
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
    }

    contactData: cockpitRegionKontaktdaten(lang: { eq: $language }) {
      id
      telephone
      lang
    }

    pageData: cockpitPageHomepage(lang: { eq: $language }) {
      id
      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        desiredAspectRatio
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }
      heroImage {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        image {
          publicURL
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
          processed1: childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        height
        desiredAspectRatio
      }
      callToAction {
        id
        published
        title
        text
        buttonLabel
        image {
          publicURL
          processed: childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      teaserText1 {
        titel
        text
        linkLabel
        link
      }
      teaserText2 {
        titel
        text
        linkLabel
        link
        imagePosition
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: FIXED, width: 160)
          }
        }
      }
      teaserText3 {
        titel
        text
      }
      teasers {
        title
        text
        target
        image {
          id
          processed: childImageSharp {
            gatsbyImageData(layout: FIXED, width: 640)
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }
  }
`;
