import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TeaserImage from './TeaserImage';

import * as styles from './TeaserImages.module.css';

TeaserImages.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};
TeaserImages.defaultProps = {
  data: [],
};

function TeaserImages(props) {
  const { data } = props;
  return (
    <div className={clsx(styles.TeaserImages, 'defaultWidth')}>
      {data.map((teaserEntry) => (
        <TeaserImage key={teaserEntry.title} data={teaserEntry} />
      ))}
    </div>
  );
}

export default TeaserImages;
