import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImage from '../../components/BackgroundImage';
import FlexibleHeightContainer from '../../components/FlexibleHeightContainer';
import TeaserText from '../../components/TeaserText';
import WidthDependentProps from '../../components/WidthDependentProps';

import OverlayPositioning from '../../shared/OverlayPositioning';

import * as styles from './FullWidthImage.module.css';

FullWidthImage.propTypes = {
  data: PropTypes.shape().isRequired,
  parallax: PropTypes.bool,
};
FullWidthImage.defaultProps = {
  parallax: false,
};

function FullWidthImage({ data, parallax }) {
  const { published, image, textPosition, height, desiredAspectRatio } = data;

  if (!published) {
    return <React.Fragment />;
  }

  const textPositionMapping = {
    unten: 'bottom',
    mitting: 'middle',
    oben: 'top',
  };

  return (
    <WidthDependentProps
      defaultProps={{
        overflow: 'hidden',
      }}
      responsiveProps={{
        default: {
          maxHeight: parseInt(height, 10) || 500,
          aspectRatio: parseFloat(desiredAspectRatio) || 2,
        },
        tablet: {
          maxHeight: -1,
        },
        mobile: {
          // adapt height to content
        },
      }}
    >
      {(width, widthDependentProps) => (
        <FlexibleHeightContainer Tag="section" className={styles.FullWidthImage} width={width} {...widthDependentProps}>
          <BackgroundImage image={image} parallax={parallax} />
          <OverlayPositioning
            verticalAlign={textPositionMapping[textPosition]}
            textAlign="left"
            positionRelative={width < 900}
          >
            <TeaserText data={data} />
          </OverlayPositioning>
        </FlexibleHeightContainer>
      )}
    </WidthDependentProps>
  );
}

export default FullWidthImage;
