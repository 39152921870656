import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MadeInAustriaOverlay from '../../components/MadeInAustriaOverlay';
import Button from '../../shared/Button';

import * as styles from './TeaserBlock.module.css';

TeaserBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape(),
  }),
  showMadeInAustria: PropTypes.bool,
};
TeaserBlock.defaultProps = {
  data: false,
  showMadeInAustria: false,
};

function TeaserBlock({ data, showMadeInAustria }) {
  const { titel: title, text, link, linkLabel } = data;

  return (
    <div className={styles.TeaserBlock}>
      <div className={clsx(styles.container, 'defaultWidth')}>
        {title && title && <h2>{title}</h2>}
        {text && text && (
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
        {link && link && <Button href={link}>{linkLabel}</Button>}
        {showMadeInAustria && <MadeInAustriaOverlay color="white" />}
      </div>
    </div>
  );
}

export default TeaserBlock;
